import useCmsTranslation from "@/hooks/useCmsTranslation";
import useScrollTo from "@/hooks/useScrollTo";
import clsx from "clsx";
// HTML Content element can not be imported dynamically
import HtmlContentElement from "@/components/contentelements/htmlContentElement/htmlContentElement";
import useStrBgImageOnPage from "@/hooks/useStrBgImageOnPage";
import {
  ceSettingById,
  CFG_CONTENT_ELEMENT_SETTING_NAME,
  valueFromStoreSetting,
} from "@/services/ceSettings/ceSettingsService";
import {
  ContentElementAccordionStoreSetting,
  ContentElementButtonStoreSetting,
  ContentElementCardsStoreSetting,
  ContentElementCarouselStoreSetting,
  ContentElementFormStoreSetting,
  ContentElementGalleryStoreSetting,
  ContentElementHeadlineStoreSetting,
  ContentElementHtmlStoreSetting,
  ContentElementImageStoreSetting,
  ContentElementImageTickerStoreSetting,
  ContentElementImageWithMarkersStoreSetting,
  ContentElementMultimediaStoreSetting,
  ContentElementQuickLinksStoreSetting,
  ContentElementRichTextStoreSetting,
  ContentElementSearchBarStoreSetting,
  ContentElementSeparatorStoreSetting,
  ContentElementSpacerStoreSetting,
  ContentElementTextWithImageStoreSetting,
} from "@/types/ceSettings/ceSettings";
import {
  CEAccordion,
  CEButton,
  CECarousel,
  CEForm,
  CEGallery,
  CEHeadline,
  CEImage,
  CEMultimedia,
  CEQuickLinks,
  CESearchBar,
  CESpacer,
  CETextWithImage,
} from "@/types/content-elements";
import { getRandomSlug } from "@/utils/util";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { Fragment } from "react";
import CmsPageWarning from "../cms/cmsPageWarning/cmsPageWarning";

const ImageWithMarkersContentElement = dynamic(
  () =>
    import(
      "@/components/contentelements/imageWithMarkersContentElement/imageWithMarkersContentElement"
    )
);

const CarouselContentElement = dynamic(
  () =>
    import(
      "@/components/contentelements/carouselContentElement/carouselContentElement"
    )
);

const SearchResultsContentElement = dynamic(
  () =>
    import(
      "@/components/contentelements/searchResultsContentElement/searchResultsContentElement"
    )
);

const AccordionContentElement = dynamic(
  () =>
    import(
      "@/components/contentelements/accordionContentElement/accordionContentElement"
    )
);

const GalleryDefaultContentElement = dynamic(
  () =>
    import(
      "@/components/contentelements/galleryContentElement/default/galleryDefaultContentElement"
    )
);
const GalleryMasonryContentElement = dynamic(
  () =>
    import(
      "@/components/contentelements/galleryContentElement/masonry/galleryMasonryContentElement"
    )
);
const GalleryGridContentElement = dynamic(
  () =>
    import(
      "@/components/contentelements/galleryContentElement/grid/galleryGridContentElement"
    )
);
const CardsContentElement = dynamic(
  () =>
    import(
      "@/components/contentelements/cardsContentElement/cardsContentElement"
    )
);
const TextWithImageContentElement = dynamic(
  () =>
    import(
      "@/components/contentelements/textWithImageContentElement/textWithImageContentElement"
    )
);
const MultimediaContentElement = dynamic(
  () =>
    import(
      "@/components/contentelements/multimediaContentElement/multimediaContentElement"
    )
);
const HeadlineContentElement = dynamic(
  () =>
    import(
      "@/components/contentelements/headlineContentElement/headlineContentElement"
    )
);
const SeparatorContentElement = dynamic(
  () => import("@/components/contentelements/separator/separatorContentElement")
);
const SpacerContentElement = dynamic(
  () =>
    import(
      "@/components/contentelements/spacerContentElement/spacerContentElement"
    )
);
const ButtonContentElement = dynamic(
  () =>
    import(
      "@/components/contentelements/buttonContentElement/buttonContentElement"
    )
);

const ImageContentElement = dynamic(
  () =>
    import(
      "@/components/contentelements/imageContentElement/imageContentElement"
    )
);

const ImageTickerContentElement = dynamic(
  () =>
    import(
      "@/components/contentelements/imageTickerContentElement/imageTickerContentElement"
    )
);

const FormContentElement = dynamic(
  () =>
    import("@/components/contentelements/formContentElement/formContentElement")
);

const RichTextContentElement = dynamic(
  () =>
    import(
      "@/components/contentelements/richTextContentElement/richTextContentElement"
    )
);

const SearchBarContentElement = dynamic(
  () =>
    import(
      "@/components/contentelements/searchBarContentElement/searchBarContentElement"
    )
);

const QuickLinksContentElement = dynamic(
  () =>
    import(
      "@/components/contentelements/quickLinksContentElement/quickLinksContentElement"
    )
);

/**
 * Props for the PageRenderer component.
 *
 * @typedef {Object} PageRendererProps
 * @property {any} data - The data used to render the page.
 * @property {boolean} showCmsPageWarnings - Flag to indicate whether to show CMS page warnings.
 * @property {boolean} isMobile - Flag to indicate whether the view is on a mobile device.
 */
export interface PageRendererProps {
  data: any;
  showCmsPageWarnings: boolean;
  isMobile: boolean;
}

/**
 * PageRenderer component that renders the page content.
 *
 * This component is responsible for rendering pages for non-authenticated
 * users, serving as the live view of the application. Additionally, it is
 * used for content managers when viewing pages without editing or when
 * using the preview mode toggle.
 *
 * This component renders the live view of the application for
 * non-authenticated users and is also used by content managers when
 * viewing pages without editing or when using the preview mode toggle.
 *
 * @param {PageRendererProps} props - The properties passed to the component.
 * @returns {JSX.Element} The rendered page content.
 */
const PageRenderer = (props: PageRendererProps) => {
  const router = useRouter();
  const tCms = useCmsTranslation();
  useScrollTo();

  const pageHasWarning = !props.data.published || props.data.isFallback;

  const { backgroundImageClassName } = useStrBgImageOnPage(
    props.data.strBgImage
  );

  const renderContentElement = (
    contentElement: any,
    index: number,
    isLastElement: boolean,
    isFirstElement: boolean
  ) => {
    const selectedContentElementSettingId = valueFromStoreSetting(
      contentElement[CFG_CONTENT_ELEMENT_SETTING_NAME],
      true
    );

    if (contentElement !== null) {
      switch (contentElement.__component) {
        case "pb.chdln":
          return (
            <HeadlineContentElement
              content={contentElement as CEHeadline}
              position={index}
              ceSettings={
                ceSettingById(selectedContentElementSettingId)
                  ?.setting as ContentElementHeadlineStoreSetting
              }
              isLastElement={isLastElement}
              isMobile={props.isMobile}
              isFirstElement={isFirstElement}
            />
          );
        case "pb.ctwi":
          return (
            <TextWithImageContentElement
              content={contentElement as CETextWithImage}
              position={index}
              ceSettings={
                ceSettingById(selectedContentElementSettingId)
                  ?.setting as ContentElementTextWithImageStoreSetting
              }
              isLastElement={isLastElement}
              isMobile={props.isMobile}
              isFirstElement={isFirstElement}
            />
          );
        case "pb.cqcklnks":
          return (
            <QuickLinksContentElement
              content={contentElement as CEQuickLinks}
              position={index}
              ceSettings={
                ceSettingById(selectedContentElementSettingId)
                  ?.setting as ContentElementQuickLinksStoreSetting
              }
              isLastElement={isLastElement}
              isMobile={props.isMobile}
              isFirstElement={isFirstElement}
            />
          );

        case "pb.csrchbr":
          return (
            <SearchBarContentElement
              content={contentElement as CESearchBar}
              position={index}
              ceSettings={
                ceSettingById(selectedContentElementSettingId)
                  ?.setting as ContentElementSearchBarStoreSetting
              }
              isLastElement={isLastElement}
              isMobile={props.isMobile}
              isFirstElement={isFirstElement}
            />
          );

        case "pb.cmg":
          return (
            <ImageContentElement
              content={contentElement as CEImage}
              ceSettings={
                ceSettingById(selectedContentElementSettingId)
                  ?.setting as ContentElementImageStoreSetting
              }
              position={index}
              isLastElement={isLastElement}
              isMobile={props.isMobile}
              isFirstElement={isFirstElement}
            ></ImageContentElement>
          );
        case "pb.cbttn":
          return (
            <ButtonContentElement
              content={contentElement as CEButton}
              ceSettings={
                ceSettingById(selectedContentElementSettingId)
                  ?.setting as ContentElementButtonStoreSetting
              }
              position={index}
              isLastElement={isLastElement}
              isMobile={props.isMobile}
              isFirstElement={isFirstElement}
            ></ButtonContentElement>
          );
        case "pb.cmltmd":
          return (
            <MultimediaContentElement
              content={contentElement as CEMultimedia}
              ceSettings={
                ceSettingById(selectedContentElementSettingId)
                  ?.setting as ContentElementMultimediaStoreSetting
              }
              position={index}
              isLastElement={isLastElement}
              isMobile={props.isMobile}
              isFirstElement={isFirstElement}
            />
          );
        case "pb.cfrm":
          return (
            <FormContentElement
              content={contentElement as CEForm}
              ceSettings={
                ceSettingById(selectedContentElementSettingId)
                  ?.setting as ContentElementFormStoreSetting
              }
              position={index}
              isLastElement={isLastElement}
              isMobile={props.isMobile}
              isFirstElement={isFirstElement}
            />
          );
        case "pb.crchtxt":
          return (
            <RichTextContentElement
              content={contentElement}
              ceSettings={
                ceSettingById(selectedContentElementSettingId)
                  ?.setting as ContentElementRichTextStoreSetting
              }
              position={index}
              isLastElement={isLastElement}
              isMobile={props.isMobile}
              isFirstElement={isFirstElement}
            />
          );
        case "pb.ccrds":
          return (
            <CardsContentElement
              content={contentElement}
              ceSettings={
                ceSettingById(selectedContentElementSettingId)
                  ?.setting as ContentElementCardsStoreSetting
              }
              position={index}
              isLastElement={isLastElement}
              isMobile={props.isMobile}
              isFirstElement={isFirstElement}
            />
          );
        case "pb.csprtr":
          return (
            <SeparatorContentElement
              content={contentElement}
              position={index}
              ceSettings={
                ceSettingById(selectedContentElementSettingId)
                  ?.setting as ContentElementSeparatorStoreSetting
              }
              isLastElement={isLastElement}
              isMobile={props.isMobile}
              isFirstElement={isFirstElement}
            />
          );
        case "pb.cmgtckr":
          return (
            <ImageTickerContentElement
              content={contentElement}
              position={index}
              ceSettings={
                ceSettingById(selectedContentElementSettingId)
                  ?.setting as ContentElementImageTickerStoreSetting
              }
              isLastElement={isLastElement}
              isMobile={props.isMobile}
              isFirstElement={isFirstElement}
            />
          );
        case "pb.cspcr":
          return (
            <SpacerContentElement
              content={contentElement as CESpacer}
              position={index}
              ceSettings={
                ceSettingById(selectedContentElementSettingId)
                  ?.setting as ContentElementSpacerStoreSetting
              }
              isLastElement={isLastElement}
              isMobile={props.isMobile}
              isFirstElement={isFirstElement}
            />
          );
        case "pb.csrchrslts":
          // Note: SearchResults has no CmsContentFrame.
          return (
            <SearchResultsContentElement
              content={contentElement}
              position={index}
              isLastElement={isLastElement}
              isMobile={props.isMobile}
              isFirstElement={isFirstElement}
            />
          );
        case "pb.ccrsl":
          return (
            <CarouselContentElement
              content={contentElement as CECarousel}
              position={index}
              ceSettings={
                ceSettingById(selectedContentElementSettingId)
                  ?.setting as ContentElementCarouselStoreSetting
              }
              isLastElement={isLastElement}
              isMobile={props.isMobile}
              isFirstElement={isFirstElement}
            />
          );
        case "pb.cccrdn":
          return (
            <AccordionContentElement
              content={contentElement as CEAccordion}
              position={index}
              ceSettings={
                ceSettingById(selectedContentElementSettingId)
                  ?.setting as ContentElementAccordionStoreSetting
              }
              isLastElement={isLastElement}
              isMobile={props.isMobile}
              isFirstElement={isFirstElement}
            />
          );
        case "pb.cgllry":
          const galleryLayouts: any = {
            default: GalleryDefaultContentElement,
            masonry: GalleryMasonryContentElement,
            grid: GalleryGridContentElement,
          };
          let GalleryComponent = null;
          switch (contentElement.cfgStrLayout?.values[0]) {
            case "masonry":
              GalleryComponent = galleryLayouts.masonry;
              break;
            case "grid":
              GalleryComponent = galleryLayouts.grid;
              break;
            default:
              GalleryComponent = galleryLayouts.default;
              break;
          }
          return (
            <GalleryComponent
              content={contentElement as CEGallery}
              ceSettings={
                ceSettingById(selectedContentElementSettingId)
                  ?.setting as ContentElementGalleryStoreSetting
              }
              position={index}
              isLastElement={isLastElement}
              isMobile={props.isMobile}
              isFirstElement={isFirstElement}
            />
          );
        case "pb.chtml":
          return (
            <HtmlContentElement
              content={contentElement}
              position={index}
              isLastElement={isLastElement}
              isFirstElement={isFirstElement}
              isMobile={props.isMobile}
              ceSettings={
                ceSettingById(selectedContentElementSettingId)
                  ?.setting as ContentElementHtmlStoreSetting
              }
            />
          );
        case "pb.cmgwthmrkrs":
          return (
            <ImageWithMarkersContentElement
              content={contentElement}
              position={index}
              isLastElement={isLastElement}
              isFirstElement={isFirstElement}
              isMobile={props.isMobile}
              ceSettings={
                ceSettingById(selectedContentElementSettingId)
                  ?.setting as ContentElementImageWithMarkersStoreSetting
              }
            />
          );
        default:
          return (
            <pre style={{ height: "250px", overflow: "auto" }}>
              {JSON.stringify(contentElement, null, 2)}
            </pre>
          );
      }
    }
  };

  return (
    <div id="page" className={clsx("page", backgroundImageClassName)}>
      {props.showCmsPageWarnings && pageHasWarning ? (
        <CmsPageWarning>
          <strong>{tCms("hint")}: </strong>
          {props.data.isFallback && !props.data.strapiLocaleExists && (
            <>
              {tCms("strapiLocaleDoesNotExist")}
              <br />
            </>
          )}
          {props.data.published &&
            props.data.isFallback &&
            props.data.strapiLocaleExists && (
              <>
                {tCms("pageDoesNotExistInLanguage", { locale: router.locale! })}
                <br />
              </>
            )}
          {!props.data.published && (
            <>
              {tCms("pageWasNotPublishedInLanguage", {
                locale: router.locale!,
              })}
              <br />
            </>
          )}

          {tCms("notFoundWillBeShown")}
        </CmsPageWarning>
      ) : null}
      {/* Requirement: published: false - should only display the warning */}
      {props.data.published ? (
        <>
          {props.data?.content?.map((pageElement: any, i: number) => {
            return (
              <Fragment
                key={`${props.data.url}-${pageElement.id}+${getRandomSlug()}`}
              >
                {renderContentElement(
                  pageElement,
                  i,
                  i === props.data.content.length - 1,
                  i === 0
                )}
              </Fragment>
            );
          })}
        </>
      ) : null}
    </div>
  );
};

export default PageRenderer;
